import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Home.css';
const Home = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/Chatbot');
  };

  return (
    <div className="home-container">
        <div className="title-container">
            <div className="title">Welcome to Videobook on KPOINT Life Insurance Customers</div>
            <div className="subtitle">
              You can talk to me about KPOINT's solutions for our Life Insurance Customers
            </div>
            <div className="pdfname">This videobook contains following documents: 
            <br/>1.KPOINT Proposal for CANARA HSBC 
            <br/>2.HDFC Life Proposal 
            <br/>3.IndiaFirst Life Proposal 
            <br/>4.KPOINT Capabilities for SUD Life 
            <br/>5.KPOINT for Edelweiss Life Insurance
            <br/>6.KPOINT Proposal for SUD Life
            <br/>7.Life Insurance email drip - Persistency
            <br/>8.Life Insurance working FY 2023 - SUD Life
            <br/>9.Templatizing Sales - Life Insurance
            <br/>10.RNLI Agent BOP flow
          </div>
            
            
        </div>
        <button onClick={handleRedirect} className="navigate-button">
        Go to Videobook
        </button>
    </div>
  );
};

export default Home;
