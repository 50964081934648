import React, { useState, useEffect, useRef, useCallback } from 'react';
import '../css/Chatbot.css';
import Icon from '../assests/Spark_logo.png';
import { FaPaperPlane, FaEye, FaMicrophone, FaVolumeUp, FaStop, FaDownload, FaFilePdf, FaRegFilePdf } from 'react-icons/fa';
import KpointPlayer from './kpointplayer'; // Assuming this is your custom KPoint player component
import { PulseLoader, ClipLoader } from 'react-spinners';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faDownload, faEye } from '@fortawesome/free-solid-svg-icons';

const Chatbot = () => {
  const playerRefs = useRef({});  // To store refs for each videoId
  const [query, setQuery] = useState('');
  const [conversation, setConversation] = useState([
    { text: 'Hello! How may I help you today?', isUser: false },
  ]);
  // const [prevIndex, setIndex] = useState(1);
  const [videoTitles, setVideoTitles] = useState({}); // Store video titles and thumbnails
  const [selectedLanguage, setSelectedLanguage] = useState('en-US');
  const [placeholder, setPlaceholder] = useState('Type your message');
  const chatbotBodyRef = useRef(null);
  const [loading, setLoading] = useState(false); // Add loading state
  const [isRecording, setIsRecording] = useState(false); // Recording state
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const silenceTimer = useRef(null);
  const analyserRef = useRef(null);
  const MIN_DECIBELS = -90; 
  const [speechloading, setSpeechLoading] = useState(false); 
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null); // Create audio reference

  const handlePlayerReady = useCallback((playerRef, videoId) => {
    
    if (playerRef) {
      playerRefs.current[videoId] = playerRef;
      console.log(`Player is ready for video ${videoId}`, playerRef); 
    } else {
      console.error("kPlayer is undefined for video", videoId);
    }
  }, []); 
  
  const onStateChange = useCallback((state, videoId) => {
    console.log(`Player state changed for video ${videoId}:`, state["data"]);
  }, []);  

   // Audio Recording Functions
  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        audioChunksRef.current = []; // Reset audio chunks

        mediaRecorderRef.current.ondataavailable = event => {
          audioChunksRef.current.push(event.data);
        };

        mediaRecorderRef.current.onstop = () => {
          const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
          sendAudioToServer(audioBlob);
        };

        mediaRecorderRef.current.start();
        console.log("recording started");
        setIsRecording(true);
        setPlaceholder('Speak now');

        const audioContext = new AudioContext();
        const audioStreamSource = audioContext.createMediaStreamSource(stream);
        analyserRef.current = audioContext.createAnalyser();
        analyserRef.current.minDecibels = MIN_DECIBELS;
        audioStreamSource.connect(analyserRef.current);

        // detectSilence(); // Start silence detection loop
        // resetSilenceTimer(); // Start silence timer
      })
      .catch(err => {
        console.error("Error accessing microphone:", err);
      });
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      console.log("stopped recording");
      clearTimeout(silenceTimer.current);
      setIsRecording(false);
      setPlaceholder('Transcribing...');
    }
  };

  const handleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const sendAudioToServer = (audioBlob) => {
    const reader = new FileReader();
    reader.readAsDataURL(audioBlob);
    // const a = Base64.encode(reader.readAsDataURL(audioBlob));
    reader.onloadend = () => {
      const base64Audio = reader.result.split(',')[1]; 
  
      //origin : fetch('https://km0yl5vd0a.execute-api.ap-south-1.amazonaws.com/dev/stt-audio', {
      //li cutomer : fetch('https://t5j4u7vykc.execute-api.ap-south-1.amazonaws.com/dev/stt-audio', {
      fetch('https://7d3m1ntlhf.execute-api.ap-south-1.amazonaws.com/dev/stt-audio', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          audio: base64Audio,
          language: selectedLanguage, 
        }),
      })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}, body: ${response.body}`);
        }
        return response.json();
      })
      .then(data => {
        setQuery(data.transcript || '');
        setPlaceholder('Type your message');
      })
      .catch(error => {
        console.error('Error:', error);
        setPlaceholder('Type your message');
      });
    };
  };
  

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  // Fetch video titles for each video ID in the videoLinks array
  const fetchVideoTitle = async (id) => {
    try {
      const response = await fetch(`https://ktpl.kpoint.com/api/v3/videos/${id}`);
      const data = await response.json();
      return data.displayname || 'Untitled Video';
    } catch (error) {
      console.error("Error fetching video info:", error);
      return 'Untitled Video';
    }
  };

  const fetchVideoTitles = async (videoLinks) => {
    const titles = {};
    await Promise.all(
      videoLinks.map(async (video) => {
        const title = await fetchVideoTitle(video.id);
        titles[video.id] = title;
      })
    );
    setVideoTitles(titles);
  };

  const handleSend = async () => {
    if (query.trim() !== '') {
      setConversation(prev => [...prev, { text: query, isUser: true }]);
      setQuery('');
      setLoading(true);

      try {
        // const response = await fetch('https://t5j4u7vykc.execute-api.ap-south-1.amazonaws.com/dev/playlist-videobot', {
        const response = await fetch('https://7d3m1ntlhf.execute-api.ap-south-1.amazonaws.com/dev/playlist-videobot', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ prompt: query, language: selectedLanguage }),
        });

        if (response.ok) {
          const data = await response.json();
          const responseBody = JSON.parse(data.body);
          const responseTime = responseBody.Timestamps;
          const responseText = responseBody.response;
          
          const videos = responseBody.VideoLinks ? responseBody.VideoLinks.map(video => ({
            id: video.id,
            url: video.url
          })) : [];

          const docs = responseBody.DocumentLinks ? responseBody.DocumentLinks.map(doc => ({
            id: doc.id,
            url: doc.url
          })) : [];

          console.log("Response text : ",responseText);
          console.log("Time response : ",responseTime);
          console.log("Video response : ",videos);
          console.log("Document response : ", docs);
          await fetchVideoTitles(videos);

          setConversation(prev => [
            ...prev,
            { 
              text: responseText,
              isUser: false, 
              videos,
              docs,
              isPlaying: false, // Initialize isPlaying state 
              speechloading: false,
             
            }
          ]);
          
          // setIndex(prevIndex => prevIndex + 1);
        } else {
          setConversation(prev => [...prev, { text: 'An error occurred. Please try again.', isUser: false }]);
        }
      } catch (error) {
        setConversation(prev => [...prev, { text: 'An error occurred. Please try again.', isUser: false }]);
      } finally {
        setLoading(false);
      }
    }
  };

  const getSpeechResponse = async (text) => {
    
    const text1 = text.split("Click on a timestamp to jump to that point in the video.")[0];
    // const text2 = text1.trim();
    
    try {
      const response = await fetch('https://7d3m1ntlhf.execute-api.ap-south-1.amazonaws.com/dev/tts-elevenlabs', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: text1 }),
      });
  
      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
  
        if (contentType && contentType.includes('application/json')) {
          const data = await response.json();
          let base64Audio = data.body.replace(/\s/g, '');
  
          // Properly pad the base64 string
          while (base64Audio.length % 4 !== 0) {
            base64Audio += '=';
          }
  
          const binaryString = atob(base64Audio);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
  
          const blob = new Blob([bytes], { type: 'audio/mpeg' });
          const audioUrl = URL.createObjectURL(blob);
  
          // Set the audio in the ref
          audioRef.current = new Audio(audioUrl);
  
          audioRef.current.oncanplaythrough = () => {
            audioRef.current.play();
            setIsPlaying(true);
          };
  
          audioRef.current.onended = () => {
            setIsPlaying(false);
            URL.revokeObjectURL(audioUrl);
          };
  
          audioRef.current.onerror = (e) => {
            console.error('Audio playback error:', e);
            setIsPlaying(false);
          };
        } else if (contentType.includes('audio')) {
          const blob = await response.blob();
          const audioUrl = URL.createObjectURL(blob);
  
          // Set the audio in the ref
          audioRef.current = new Audio(audioUrl);
  
          audioRef.current.oncanplaythrough = () => {
            audioRef.current.play();
            setIsPlaying(true);
          };
  
          audioRef.current.onended = () => {
            setIsPlaying(false);
            URL.revokeObjectURL(audioUrl);
          };
  
          audioRef.current.onerror = (e) => {
            console.error('Audio playback error:', e);
            setIsPlaying(false);
          };
        }
      }
    } catch (error) {
      console.error('Error fetching or processing speech response:', error);
    } finally {
      setSpeechLoading(false);
    }
  };
  
  const scrollToBottom = () => {
    if (chatbotBodyRef.current) {
      chatbotBodyRef.current.scrollTop = chatbotBodyRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSend();
    }
  };

  const handleVideoClick = (video) => {
    if (video && video.url) {
      window.open(video.url, '_blank');
    } else {
      console.error("Invalid video URL");
    }
  };

  const seekTo = (videoId, startTime) => {
    const player = playerRefs.current[videoId];
    if (player && startTime) {
      const timeParts = startTime.split(':').map(Number);
      if (timeParts.length === 3) {
        const [hours, minutes, seconds] = timeParts;
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
        if (!isNaN(totalSeconds) && isFinite(totalSeconds)) {
          const timeMs = totalSeconds * 1000;  // Convert to milliseconds
          console.log(`Seeking video ${videoId} to ${timeMs}ms`);
          playerRefs.current[videoId].seekTo(timeMs);
        } else {
          console.error("Invalid time format: non-finite value");
        }
      } else {
        console.error("Invalid time format: expected HH:MM:SS");
      }
    } else {
      console.error("Player is not available or startTime is undefined for video", videoId);
      console.log('Available playerRefs:', Object.keys(playerRefs.current));
    }
  };  

  const convertS3ToHttpUrl = (s3Url) => {
    if (!s3Url.startsWith('s3://')) return s3Url;
    const [, bucketAndPath] = s3Url.split('s3://');
    const [bucket, ...pathParts] = bucketAndPath.split('/');
    return `https://${bucket}.s3.amazonaws.com/${pathParts.join('/')}`;
  };

  const handleDownload = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop(); // Extracts the file name from the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderDocuments = (docs) => {
    if (!docs || docs.length === 0) return null;
    return (
      <div className="document-section">
        <div className="instruction">Read more:</div>
        {docs.map((doc, i) => {
          const httpUrl = convertS3ToHttpUrl(doc.url);
  
          return (
            <div key={i} className="document-item">
              <FontAwesomeIcon icon={faFilePdf} className="document-icon" />
              <span className="document-name">{doc.id}</span>

              <a href={httpUrl} target="_blank" rel="noopener noreferrer" className="preview-icon">
                <FontAwesomeIcon icon={faEye} />
              </a>
  
              {/* <a href={httpUrl} target="_blank" rel="noopener noreferrer" className="download-pdf-icon" onClick={() => handleDownload(httpUrl)}>
                <FontAwesomeIcon icon={faDownload} /> 
              </a> */}
            </div>
          );
        })}
      </div>
    );
  };

  const renderVideos = (videos) => {
    if (!videos || videos.length === 0) return null;
    return (
      <div className="video-section">
        <div className="instruction">Watch Videos:</div>
        {videos.map((video, i) => {
          const videoTitle = videoTitles[video.id] || 'Untitled Video';
          return (
            <div key={i} className="video-item">
              <div className="video-title">{videoTitle}</div>
              <div className="video-player">
                <KpointPlayer
                  videoId={video.id}
                  onPlayerReady={(playerRef) => handlePlayerReady(playerRef, video.id)}
                  onStateChange={(state) => onStateChange(state, video.id)}
                />
              </div>
              <button
                className="watch-video-button"
                onClick={() => handleVideoClick(video)}
              >
                Watch Video
              </button>
            </div>
          );
        })}
      </div>
    );
  };

  const renderMessage = (msg, index) => {
    if (!msg || !msg.text) return null;
  
    const parts = msg.text.split('\n');
    const renderedParts = [];
    parts.forEach((line, i) => {
      const timestampMatch = line.match(/^(\d{1,2}:\d{2}:\d{2})/);
      const videoIdMatch = line.match(/gcc-[\w-]+/); // Extract the video ID
  
      if (timestampMatch && videoIdMatch) {
        const timestamp = timestampMatch[1];
        const videoId = videoIdMatch[0]; // Get the video ID
        const videoTitle = videoTitles[videoId] || 'Untitled Video'; // Use title from videoTitles or fallback
        
        const description = line.replace(timestampMatch[0], '').replace(videoIdMatch[0], videoTitle).trim();
        renderedParts.push(
          <div className='message-space' key={i}>
            <a href="#" onClick={(e) => { e.preventDefault(); seekTo(videoId, timestamp); }}>
              {timestamp}
            </a>
            {' ' +description }
          </div>
        );
      } else if (!line.startsWith('TIMESTAMPS:')) {
        renderedParts.push(<div className='message-space' key={i}>{line}</div>);
      }
      // else if (line.startsWith('-')) {
      //   renderedParts.push(<li key={i}>{line.substring(1).trim()}</li>);
      // }
    });
  
    // Audio play/stop buttons
    const handleAudioPlay = async () => {
      const updatedConversation = [...conversation];
      updatedConversation[index].speechloading = true;
      setConversation(updatedConversation);
      await getSpeechResponse(msg.text);
      updatedConversation[index].isPlaying = true;
      updatedConversation[index].speechloading = false;
      setConversation(updatedConversation);
    };
  
    const handleStopAudio = () => {
      if (audioRef.current) {
        audioRef.current.pause();
        const updatedConversation = [...conversation];
        updatedConversation[index].isPlaying = false;
        setConversation(updatedConversation);
      }
    };
  
    return (
      <div>
        {renderedParts}
        {!msg.isUser && (
        <>
          {renderDocuments(msg.docs)}
          {renderVideos(msg.videos)} {/* Render the video section */}
        </>
      )}
        {/* <div>
          {msg.speechloading ? (
            <ClipLoader className='speech-ClipLoader-small' size={10} color="#30c484" />
          ) : msg.isPlaying ? (
            <button className="speech-stop-button" onClick={handleStopAudio}>
              <FaStop />
            </button>
          ) : (
            <button className="speech-button" onClick={handleAudioPlay}>
              <FaVolumeUp />
            </button>
          )}
        </div> */}
      </div>
    );
  };
  

  return (
    <div className="chatbot-container">
      <div className="chatbot-header">
        <img className="icon" alt="icon" src={Icon} />
        <span>Videobook on KPOINT Life Insurance Customers</span>
      </div>
      
      <div className="chatbot-body" ref={chatbotBodyRef}>
        {conversation.map((msg, index) => (
          <div key={msg.responseId || index} className={`message ${msg.isUser ? 'user-message' : 'bot-message'}`}>
            {renderMessage(msg, index)}
            
          </div>
        ))}
        {loading && (
          <div className="loader-container">
            <PulseLoader size={8} color="#30c484" />
          </div>
        )}
      </div>

      <div className="chatbot-footer">
      
        <input
          className="chat-input"
          type="text"
          value={query}
          placeholder={placeholder}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <button onClick={handleSend}>
          <FaPaperPlane />
          {/* Send */}
        </button>
        {/* <button id='startrecording' onClick={handleRecording}>
          <FaMicrophone/>
        </button> */}
        <select
          className="language-select"
          id="languageSelect"
          value={selectedLanguage}
          onChange={handleLanguageChange}   
        > 
          <option value="English">English</option>
          <option value="Bengali">Bengali</option>
          <option value="Gujarati">Gujarati</option>
          <option value="Hindi">Hindi</option>
          <option value="Kannada">Kannada</option>
          <option value="Malayalam">Malayalam</option>
          <option value="Marathi">Marathi</option>
          <option value="Punjabi">Punjabi</option>
          <option value="Tamil">Tamil</option>
          <option value="Telugu">Telugu</option>
        </select>
      </div>
    </div>
  );
};

export default Chatbot;
